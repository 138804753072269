//Controller
import BaseController from "@/controllers/base-controller";

//Standard Components
import GlobalServices from "@/services/global";
import ApplicationService from "@/services/application";
import EarthApplicationService from "@/services/earth-application";
import EVENTS from "@/constants/events";
import MODULE from "@/constants/modules";
import APP from "@/constants/application";
import UtilityString from "@/utilities/strings";

import API_CONFIG from "@/config/API_CONFIG";
import Router from "@/router";
import AuthService from "@/auth/authService";
import {IController} from "@/interfaces/IController";
import {isDev} from "@/config/prototypes";
import SearchService from "@/services/search";
import InvoiceService from "@/services/invoice";
//Interfaces
import {IUXSidePanel, ISidePanelHeader, IActionPrint, ILabelTitle, IButtonAction, ISidePanelInfoSection, IJupiterIFrame, ISidePanelTabs} from "@/interfaces/UXSidePanel";

import {IUXList, IListData, ITableHeader, ITable, ITableHoverMenu, ITableAction, IEditAction, IRoute} from "@/interfaces/IUXList";
import {IInfo} from "@/interfaces/IUXInfo";

//Services
import {IFilterClient, IFilterItem} from "@/interfaces/IUXFilters";
import store from "@/store";

class InvoiceController implements IController {
  private config = new API_CONFIG();
  private auth = AuthService.getInstance();
  private utility = new UtilityString();
  private app = new ApplicationService();
  private global = new GlobalServices();

  private search = new SearchService();
  private invoiceService = new InvoiceService();

  _actionId = MODULE.INVOICE.ActionId;
  _moduleId = MODULE.INVOICE.ModuleId;

  //Standard application event types, to standardize event logs and modal events
  EVENT_ACTION = EVENTS;

  //Stores the full data object state of the side panel to be returned.
  _sidepanel: IUXSidePanel = new Object() as IUXSidePanel;

  //Stores the default action, if the side panel action button is clicked without choosing an option from the list
  _defaultAction: IButtonAction = new Object() as IButtonAction;

  //The data object being passed in, eg.. Project, Client , Quote Data etc..
  _data = null;

  //Standard functionality such as print, email, close are stored in the base controller
  _base = new BaseController();

  //Reference to project earth for backward compability
  earth = new EarthApplicationService();

  //Constructor Takes in a Module Data Object (Project, Client, Invoice)
  constructor() {}

  //Page Header / Title
  getTitle(): String {
    let balance = "";

    if (this._data.approvedTotal) balance = "Balance: " + this._data.approvedTotal;
    else balance = "Paid";

    let title = this._base.getTitle("Invoice", this._data.referenceNumber, balance);

    return title;
  }

  getSubTitle() {
    let subtitle = this._base.getSubTitle(this._data.name, this._data.location);

    return subtitle;
  }

  //Generate Drop Down Menu for Action Items
  getDropDownMenu(): IButtonAction[] {
    let actions: IButtonAction[] = [];
    const singleRecordPayment = {
      id: 8,
      menuTitle: "Record Payment",
      modalTitle: "Record Payment",
      saveButtonTitle: "Continue",
      data: this._data,
      details: this._sidepanel.details,
      componentName: this.EVENT_ACTION.INVOICE.RecordSinglePayment,
      eventType: this.EVENT_ACTION.INVOICE.RecordSinglePayment,
      UI_TYPE: APP.UI_TYPE.Modal,
    };

    // Set Default Action // override default action at line 99
    this._defaultAction = singleRecordPayment;
    actions.push(singleRecordPayment);

    let workOrderURL = this.earth.getViewWorkOrderNavUrlJupiterProtal(this._data.idRefNumber1, this._data.name); // idRefNumber1 = intProjectId
    actions.push({
      id: 2,
      eventType: this.EVENT_ACTION.DASHBOARD.View,
      menuTitle: "Edit Job/Work Order",
      modalTitle: "Edit Job/Work Order",
      saveButtonTitle: "Save",
      data: this._data,
      componentName: "",
      url: workOrderURL,
      UI_TYPE: APP.UI_TYPE.NewTab,
    });

    let clientUrl = this.earth.getViewClientNavigationUrlJupiterPortal(this._data.idRefNumber2, this._data.name); // idRefNumber2 = clientIId
    actions.push({
      id: 3,
      eventType: this.EVENT_ACTION.CLIENTS.View,
      menuTitle: "Edit Client",
      modalTitle: "Edit Client",
      saveButtonTitle: "Save",
      data: this._data,
      componentName: "",
      url: clientUrl,
      UI_TYPE: APP.UI_TYPE.NewTab,
    });

    if (isDev()) {
      //Record Payment (new)
      let recordPayment = {
        id: 4,
        menuTitle: "Record Payment (New)",
        modalTitle: "Record Payment",
        saveButtonTitle: "Save",
        data: this._data,
      } as any;

      recordPayment.componentName = this.EVENT_ACTION.INVOICE.RecordPayment;
      recordPayment.eventType = this.EVENT_ACTION.INVOICE.RecordPayment;
      recordPayment.UI_TYPE = APP.UI_TYPE.Modal;

      actions.push(recordPayment);
    }
    // Duplicate Invoice function

    actions.push({
      id: 5,
      eventType: this.EVENT_ACTION.INVOICE.DuplicateInvoice,
      menuTitle: "Duplicate Invoice",
      modalTitle: "Duplicate Invoice",
      saveButtonTitle: "Duplicate",
      data: this._data,
      componentName: "DuplicateInvoice",
      UI_TYPE: APP.UI_TYPE.Modal,
    });

    // Old payment
    const oldRecordPayment = {
      id: 1,
      menuTitle: "Old Record Payment",
      modalTitle: "Old Record Payment",
      saveButtonTitle: "Save",
      data: this._data,
      eventType: this.EVENT_ACTION.IFRAME.RecordPaymentOld,
      componentName: this.EVENT_ACTION.IFRAME.RecordPaymentOld,
      UI_TYPE: APP.UI_TYPE.ModalIFrame,
    };
    actions.push(oldRecordPayment);

    return actions;
  }

  //SECTION - Header
  getHeader(): ISidePanelHeader {
    let headerDataObj: ISidePanelHeader = new Object() as ISidePanelHeader;

    //Hide/Show Print Preview Button
    headerDataObj.isPreviewEnabled = true;

    //Hide/Show Email Button
    headerDataObj.isEmailEnabled = true;

    //Hide/Show Action Button
    headerDataObj.isActionButtonEnabled = true;

    headerDataObj.titleSection = new Object() as ILabelTitle;

    //Title Section (Left)
    headerDataObj.titleSection.headTitle = this.getTitle();

    //Sub Title
    headerDataObj.titleSection.subTitle = this.getSubTitle();

    //Action Button
    // headerDataObj.actions = this.getDropDownMenu();

    // headerDataObj.defaultAction = this._defaultAction;

    //Action Button
    if (headerDataObj.isActionButtonEnabled) {
      headerDataObj.actions = this.getDropDownMenu();
      headerDataObj.defaultAction = this._defaultAction;
    }

    //Print Button
    if (headerDataObj.isPreviewEnabled) {
      headerDataObj.print = this._base.getPrintAction(this._data.id, this._moduleId);
    }

    //Email Button
    if (headerDataObj.isEmailEnabled) {
      headerDataObj.email = this._base.getEmailAction(this._data, this._moduleId, this._actionId);
    }

    return headerDataObj;
  }

  //SECTION - Action Bar
  getActionBar(): IButtonAction[] {
    let actions: IButtonAction[] = [];

    //Edit Client
    // let clientUrl = this.earth.getViewClientNavigationUrl(this._data.intClientID, this._data.businessName);

    // actions.push({
    //   id: 1,
    //   eventType: this.EVENT_ACTION.CLIENTS.View,
    //   menuTitle: "Edit Client",
    //   modalTitle: "Editing Client",
    //   saveButtonTitle: "Save Changes",
    //   data: this._data,
    //   componentName: "",
    //   url: clientUrl,
    //   icon: "fa fa-pencil",
    //   UI_TYPE: APP.UI_TYPE.NewTab,
    // });

    //Map
    let mapUrl = "https://www.google.com/maps/search/?api=1&query=" + encodeURI(this._data.location);

    actions.push({
      id: 2,
      eventType: this.EVENT_ACTION.CLIENTS.ExternalView,
      menuTitle: "Map",
      modalTitle: "",
      saveButtonTitle: "",
      data: this._data,
      componentName: "",
      url: mapUrl,
      icon: "fa fa-map-marker",
      UI_TYPE: APP.UI_TYPE.NewTab,
    });

    //Dispatch
    actions.push({
      id: 3,
      eventType: this.EVENT_ACTION.DISPATCH.New,
      menuTitle: "Dispatch",
      modalTitle: "Dispatch to Technician",
      saveButtonTitle: "Send Message",
      data: this._data,
      componentName: "NewDispatch",
      url: "",
      icon: "fa fa-truck",
      UI_TYPE: APP.UI_TYPE.Modal,
    });

    //Sales Quote Navigation
    //If has sales quotes
    if (this._data.intSalesQuotationID > 0) {
      let quoteUrl = this.earth.getViewQuoteDetailsUrl(this._data.intSalesQuotationID);

      actions.push({
        id: 4,
        eventType: this.EVENT_ACTION.CLIENTS.View,
        menuTitle: "View Quote",
        modalTitle: "Editing Quote",
        saveButtonTitle: "Save Changes",
        data: this._data,
        componentName: "",
        url: quoteUrl,
        icon: "fa fa-file-text-o",
        UI_TYPE: APP.UI_TYPE.NewTab,
      });
    }

    return actions;
  }

  //SECTION - Info Summary - Read only
  getInfoSectionDetails(): ISidePanelInfoSection {
    let infosection: ISidePanelInfoSection = {
      //Left Side of info section
      leftSection: {
        title: "Due Date:" + " " + this._data.displayDueDate, //Red title H3 font size
        labels: [
          {
            id: 0,
            title: "Start Date: ",
            labelValue: this._data.displayStartDate,
          },
          {
            id: 1,
            title: "Est. Completion Date: ",
            labelValue: this._data.displayEndDate,
          },
        ],
      },

      //Right Side of info section
      rightSection: {
        title: "OVERDUE BY 1 DAY", //Red title H3 font size
        labels: [
          //{ id: 0, title: "Total: ", labelValue: this.$options.filters.currency(project.totalAmonunt) },
          {
            id: 1,
            title: "Created: ",
            labelValue: this._data.displayCreatedDate,
          },
          {id: 2, title: "Created By: ", labelValue: this._data.createdBy},
          // { id: 3, title: "Outstanding Balance: ", labelValue: Vue.$options.filters.currency(project.totalAmonunt) },
        ],
      },
    };

    return infosection;
  }

  //SECTION - Tabs
  getTabSection(): ISidePanelTabs[] {
    let tabs: ISidePanelTabs[] = [];

    //TAB - Invoice Details
    let detailsTab = {
      id: 1,
      title: "Invoice Details", //Name of the title
      actionButton: null,
      componentName: "InvoiceDetails",
      componentPath: "@/components/invoice/details",
      param: {
        //Dynamically passs any additional paramaters required for api calls etc.
        id: this._data.id,
        parentActionId: 0, //Parent Table the actionId table must be filtered on, by Id
        actionId: 0, //Action Id to determine what table list data to return
      }, //Action Id to determine what table list data to return
      data: this._data, //Any Data need passing to the modal box
      details: this._sidepanel.details,
      UI_TYPE: APP.UI_TYPE.Modal, //APP.UI_TYPE.Modal //The type of modal or new tab that should be opened.
    };

    tabs.push(detailsTab);

    //TAB - Description
    let descriptionTab = {
      id: 2,
      title: "Description", //Name of the Tab
      actionButton: null,
      componentName: "InvoiceDescription",
      componentPath: "@/components/invoice/description",
      param: {
        //Dynamically passs any additional paramaters required for api calls etc.
        id: this._data.id,
        parentActionId: 0, //Parent Table the actionId table must be filtered on, by Id
        actionId: this._actionId, //Action Id to determine what table list data to return
      }, //Action Id to determine what table list data to return
      data: this._data, //Any Data need passing to the modal box
      details: this._sidepanel.details,
      UI_TYPE: APP.UI_TYPE.Modal, //APP.UI_TYPE.Modal //The type of modal or new tab that should be opened.
    };

    tabs.push(descriptionTab);

    let notesTab = {
      id: 3,
      title: "Notes", //Name of the Tab
      actionButton: null,
      componentName: "NotesUniversal",
      componentPath: "@/components/_universal/notes-universal",
      param: {
        //Dynamically passs any additional paramaters required for api calls etc.
        id: this._data.id,
        parentActionId: MODULE.INVOICE.ActionId, //Parent Table the actionId table must be filtered on, by Id
        actionId: MODULE.NOTES.ActionId, //Action Id to determine what table list data to return
      }, //Action Id to determine what table list data to return
      data: this._data, //Any Data need passing to the modal box
      UI_TYPE: APP.UI_TYPE.Modal, //APP.UI_TYPE.Modal //The type of modal or new tab that should be opened.
    };

    tabs.push(notesTab);

    //TAB - Documents/Photos
    let documentsTab = {
      id: 4,
      title: "Documents/Photos", //Name of the Tab
      actionButton: this.getActionButton(MODULE.DOCUMENTS, "new", APP.UI_TYPE.Modal),
      componentName: "UniversalDocuments",
      componentPath: "@/components/_universal/tab-documents",
      param: {
        //Dynamically passs any additional paramaters required for api calls etc.
        id: this._data.id,
        parentActionId: MODULE.INVOICE.ActionId, //Parent Table the actionId table must be filtered on, by Id
        actionId: MODULE.DOCUMENTS.ActionId, //Action Id to determine what table list data to return
      }, //Action Id to determine what table list data to return
      data: this._data, //Any Data need passing to the modal box
      UI_TYPE: APP.UI_TYPE.Modal, //APP.UI_TYPE.Modal //The type of modal or new tab that should be opened.
    };

    tabs.push(documentsTab);

    //TAB - Payments
    let paymentsTab = {
      id: 5,
      title: "Payments", //Name of the Tab
      actionButton: this.getActionButton(MODULE.PAYMENT, "new", APP.UI_TYPE.Modal),
      editButton: null,
      componentName: "GenericTabList",
      componentPath: "@/components/_universal/tab-component-list",
      param: {
        //Dynamically passs any additional paramaters required for api calls etc.
        id: this._data.id,
        parentActionId: MODULE.INVOICE.ActionId, //Parent Table the actionId table must be filtered on, by Id
        actionId: MODULE.PAYMENT.ActionId, //Action Id to determine what table list data to return
      },
      data: this._data, //Any Data need passing to the component
      UI_TYPE: APP.UI_TYPE.Modal, //APP.UI_TYPE.Modal //The type of modal or new tab that should be opened.
      //Call Back function to load Location DropDown List Results
      uxdata: async (pageName: string): Promise<Object> => {
        let uxInventory = await this.global.getUXDropDown(MODULE.INVENTORY.ActionId, null, null, null);

        return {
          uxInventory,
        };
      },
    };

    tabs.push(paymentsTab);

    //TAB - History
    let historyTab = {
      id: 6,
      title: "History", //Name of the Tab
      actionButton: null,
      componentName: "Activity",
      componentPath: "@/components/activity/activity-history",
      param: {
        //Dynamically passs any additional paramaters required for api calls etc.
        id: this._data.id,
        parentActionId: MODULE.INVOICE.ActionId, //Parent Table the actionId table must be filtered on, by Id
        actionId: 0, //Action Id to determine what table list data to return
        moduleId: MODULE.INVOICE.ModuleId,
      }, //Action Id to determine what table list data to return
      data: this._data, //Any Data need passing to the modal box
      UI_TYPE: APP.UI_TYPE.Modal, //APP.UI_TYPE.Modal //The type of modal or new tab that should be opened.
    };

    tabs.push(historyTab);

    if (isDev()) {
      //TAB - External Applications
      let externalApplications = {
        id: 7,
        title: "External Applications", //Name of the Tab
        actionButton: null,
        componentName: "ExternalApplications",
        componentPath: "@/components/invoice/external-applications",
        param: {
          //Dynamically passs any additional paramaters required for api calls etc.
          id: this._data.id,
          parentActionId: MODULE.INVOICE.ActionId, //Parent Table the actionId table must be filtered on, by Id
          actionId: 0, //Action Id to determine what table list data to return
        }, //Action Id to determine what table list data to return
        data: this._data, //Any Data need passing to the modal box
        UI_TYPE: APP.UI_TYPE.Modal, //APP.UI_TYPE.Modal //The type of modal or new tab that should be opened.
      };

      tabs.push(externalApplications);
    }

    let settingsTab = {
      id: 9,
      title: "Settings/Options", //Name of the Tab
      actionButton: null,
      componentName: "InvoiceSettings",
      componentPath: "@/components/invoice/settings",
      param: {
        //Dynamically passs any additional paramaters required for api calls etc.
        id: this._data.id,
        parentActionId: 0, //Parent Table the actionId table must be filtered on, by Id
        actionId: MODULE.INVOICE.ActionId, //Action Id to determine what table list data to return
      }, //Action Id to determine what table list data to return
      data: this._data, //Any Data need passing to the modal box
      details: this._sidepanel.details,
      UI_TYPE: APP.UI_TYPE.Modal, //APP.UI_TYPE.Modal //The type of modal or new tab that should be opened.
      uxdata: async (pageName: string): Promise<Object> => {
        return {};
      },
    };
    tabs.push(settingsTab);

    return tabs;
  }

  //Dynamically Create Action Button Properties
  getActionButton(module, actionType, UI_MODAL_TYPE) {
    let title = "";
    let componentName = "";
    let eventType: any = this.EVENT_ACTION.DOCUMENTS;

    //Set Billing Rates Tab Details
    if (module === MODULE.DOCUMENTS) {
      eventType = this.EVENT_ACTION.DOCUMENTS_PHOTOS;

      if (actionType === "new") {
        title = "Add Documents/Photos";
        componentName = this.EVENT_ACTION.DOCUMENTS_PHOTOS.New; // need to update
      } else if (actionType === "edit") {
        title = "Preview Document/Photo";
        componentName = this.EVENT_ACTION.IFRAME.PreviewImage;
      }
    }

    if (module === MODULE.PAYMENT) {
      eventType = this.EVENT_ACTION.INVOICE.RecordSinglePayment;

      if (actionType === "new") {
        title = "Record Payment";
        // componentName = isDev() ? this.EVENT_ACTION.INVOICE.RecordPayment : this.EVENT_ACTION.IFRAME.RecordPaymentOld; // need to update
        componentName = this.EVENT_ACTION.INVOICE.RecordSinglePayment;
      }
    }

    if (actionType === "new") {
      return {
        id: 1,
        actionId: module.ActionId,
        parentActionId: this._actionId,
        eventType: eventType.New,
        menuTitle: title,
        modalTitle: title,
        saveButtonTitle: "Save",
        data: this._data,
        componentName: componentName,
        isInEditMode: false,
        url: "",
        icon: "fa fa-truck",
        UI_TYPE: UI_MODAL_TYPE,
        dataObj: null, //Will be set in the component
        moduleId: this._moduleId,
      };
    } else if (actionType === "edit") {
      return {
        id: 2,
        actionId: module.ActionId,
        eventType: eventType.View,
        menuTitle: title,
        modalTitle: title,
        saveButtonTitle: "Save",
        data: this._data,
        componentName: componentName,
        isInEditMode: true,
        url: "",
        icon: "fa fa-truck",
        UI_TYPE: UI_MODAL_TYPE,
        dataObj: null, //Will be set in the component
      };
    }
  }

  getListHeaders(): ITableHeader[] {
    let header: ITableHeader[] = [];

    header.push({
      id: 0,
      title: "Invoice #",
      columnName: "referenceNumber",
      isSortable: false,
      isVisible: true,
    });

    header.push({
      id: 1,
      title: "Status",
      columnName: "htmlBadge1",
      isSortable: false,
      isVisible: true,
      isHtmlColumn: true,
    });

    header.push({
      id: 2,
      title: "Client",
      columnName: "name",
      isSortable: false,
      isVisible: true,
    });

    header.push({
      id: 3,
      title: "Description",
      columnName: "description",
      isSortable: false,
      isVisible: true,
    });

    header.push({
      id: 4,
      title: "Total",
      columnName: "total",
      isSortable: false,
      isVisible: true,
      rightAlign: true,
    });

    header.push({
      id: 6,
      title: "Outstanding",
      columnName: "approvedTotal",
      isSortable: false,
      isVisible: true,
      rightAlign: true,
    });

    header.push({
      id: 7,
      title: "Created By",
      columnName: "createdBy",
      isSortable: false,
      isVisible: true,
      rightAlign: true,
    });

    header.push({
      id: 8,
      title: "Invoice Date",
      columnName: "createdDate",
      isSortable: false,
      isVisible: true,
      rightAlign: true,
    });

    return header;
  }

  getHoverMenu(): ITableHoverMenu {
    let hover = new Object() as ITableHoverMenu;

    //Not implemented for now
    hover.isEnabled = false;

    return hover;
  }

  getActions(): ITableAction {
    let actions = new Object() as ITableAction;

    //Not implemented for now
    actions.isEnabled = false;

    return actions;
  }

  //Info stats returned at the top of every list page.
  async getInfoList(): Promise<IInfo> {
    let info = new Object() as IInfo;

    //Not implemented for now
    info.isEnabled = false;

    let filter = {
      actionId: this._actionId,
    };

    let results = await this.search.getResults("global/list/info", filter);

    info.data = results.resources;

    return info;
  }

  //Dynamically determin the filters for each list page...
  async getFilters(): Promise<IFilterClient> {
    let filter = new Object() as IFilterClient;
    let filters: IFilterItem[] = [];

    //If the advance filter button options should show
    filter.hasAdvancedFilter = false;

    //Not implemented for now
    filter.isEnabled = false;

    //Filter by Purchase Order #
    filters.push({
      id: 0,
      title: "Invoice/Job #",
      name: "invoiceno",
      uxtype: "textbox",
      data: null,
      selectedValue: "",
      defaultValue: "",
      query: "i.invoiceno",
      isfilterByDisplayName: true,
    });

    //Filter by Supplier/Job Site
    filters.push({
      id: 1,
      title: "Client",
      name: "businessname",
      uxtype: "textbox",
      data: null,
      selectedValue: "",
      defaultValue: "",
      query: "c.businessname",
      isfilterByDisplayName: true,
    });

    //Filter by Status
    filters.push({
      id: 2,
      title: "Status",
      name: "status",
      uxtype: "dropdown",
      data: await this.global.getUXDropDown(MODULE.INVOICE.UX.InvoiceStatus, null, null, null),
      selectedValue: 1,
      defaultValue: 1,
      query: "IntInvoiceStatusID",
      isfilterByDisplayName: false,
    });

    //Date Range
    // filters.push({
    //     id: 3,
    //     title: "Date Range",
    //     name: "dateRange",
    //     uxtype: "daterange",
    //     data: null,
    //     selectedValue: 1,
    //     defaultValue: 1,
    //     query: "dateRange",
    //     isfilterByDisplayName: true
    // });

    filters.push({
      id: 4,
      title: "Created By",
      name: "createdby",
      uxtype: "dropdown",
      data: await this.global.getUXDropDownByColumnName(MODULE.INVOICE.ActionId, "createdBy"),
      selectedValue: 1,
      defaultValue: 1,
      query: "i.createdBy",
      isfilterByGuid: true,
    });

    filter.filters = filters;

    return filter;
  }

  async getListResults(filter) {
    filter.actionId = this._actionId;

    return await this.search.getResults("global/list", filter);
  }

  //LIST - Entry for list page
  async list(): Promise<IUXList> {
    let list: IUXList = new Object() as IUXList;

    list.actionId = this._actionId;

    //settings
    list.settings = {
      isHoverOverRowEnabled: true,
    };

    //Table Header Properties
    list.table = new Object() as ITable;
    list.table.header = this.getListHeaders();

    //Data
    list.table.data = await this.getListResults({});

    //List Table
    list.info = await this.getInfoList();

    //True - if we should load old ProjectEarth implementation via Iframe
    //Going Live!!! Leaving this code here incase we need to revert - 17th Nov 2022 - Dwain
    //list.isSidePanelFrame = !isDev(); // if we are on dev false else true

    //Filters
    list.filter = await this.getFilters();

    //Hover Menu
    list.table.hover = new Object() as ITableHoverMenu;
    list.table.hover = this.getHoverMenu();

    //More Options - Action Menu
    list.table.actions = new Object() as ITableAction;
    list.table.actions = this.getActions();

    //Edit Action - i.e. when user clicks Edit, what should happen.
    list.editAction = new Object() as IEditAction;
    list.editAction.route = new Object() as IRoute;
    list.editAction.route = {
      name: "invoice-details",
      path: "/invoices/details",
      param: {
        id: 0, //Id will be set once the record has been clicked
      },
    };

    return list;
  }

  //MAIN - Entry for Details page
  async main(data): Promise<IUXSidePanel> {
    this._data = data;
    this._data.typeId = MODULE.NOTES.INVOICE;
    this._sidepanel = store.getters.getSidePanel;
    this._sidepanel.actionId = this._actionId;

    //Specify the name of the page being loaded
    this._sidepanel.pageName = APP.PAGE.Billing.Details;

    //Decide if Side panel should show Iframe or load component view
    if (this._sidepanel.isSidePanelFrame) {
      this._sidepanel.url = this.earth.getInvoiceDetailsUrlV2(this._data);
    } //IF not an IFrame then load standard data component
    else {
      //Load details data
      // this._sidepanel.details = await this.global.getEditDetails(this._actionId, data.id);
      const details = await this.invoiceService.getInvoiceEditPreset(data.id);
      this._sidepanel.details = {...details, id: data.id};

      //Action Bar
      this._sidepanel.actionBarDataObj = this.getActionBar();

      //Info Section
      this._sidepanel.infoSectionDataObj = this.getInfoSectionDetails();

      // set to true
      this._sidepanel.info = {
        isEnabled: true,
        data: [],
        isDetailsView: true,
      };

      this._sidepanel.moduleId = this._moduleId;

      //Header
      this._sidepanel.headerDataObj = this.getHeader();

      //External Links
      this._sidepanel.iFrame = this._base.getIFrameDetails(this._data, this._data.id);

      //Tabs
      this._sidepanel.tabSectionDataObj = this.getTabSection();
    }
    return this._sidepanel;
  }
}

export default InvoiceController;
